@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.info-patient {
  width: 60%;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.span-radio {
  margin-left: 1rem;
  color: #475561;
}
.span-label {
  width: 50% !important;
  text-align: center;
}
.col-symptoms {
  margin: auto;
  width: 60%;
}
.input-symptoms {
  font-size: 0.9rem !important;
  padding-bottom: 1.3rem !important;
  padding-top: 1.3rem !important;
  width: 38% !important;
  border-radius: 0px;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.button-search-symptoms {
  background-color: #3a97ef;
  color: white;
  border: 1px solid #ced4da;
  -webkit-border-top-right-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  -moz-border-radius-topright: 9px;
  -moz-border-radius-bottomright: 9px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  margin-left: -1px;
}

.button-remove-symptoms {
  border-style: none;
  background-color: #8c9dad !important;
  color: white;
  border-color: #2088e9;
  padding: 1px 1rem 1px 1rem;
  margin-top: 0.5rem;
  border-radius: 20px;
  transition: 1s;
}

.button-remove-symptoms:hover {
  background-color: #3a4249 !important;
}

.button-add-symptoms {
  border-style: none;
  background-color: #1576d1 !important;
  color: white;
  border-color: #2088e9;
  padding: 1px 1rem 1px 1rem;
  margin-top: 0.5rem;
  border-radius: 20px;
  transition: 1s;
}

.button-add-symptoms:hover {
  background-color: #0d508e !important;
}

.text-options-possible {
  margin: auto;
  font-size: 1.563rem;
  font-family: roboto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #2a3743 !important;
}
.button-off {
  background-color: #fe6967;
  border: #fe6967;
  border-radius: 20rem;
  height: 50px;
  width: 50px;
  display: block;
  margin-left: auto;
}

#main-row {
  height: calc(100vh - 8vh);
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 600px) {
  .button-large {
    padding: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

label > input {
  margin-right: 5px;
}
