@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap);
a{
    font-size: 14px;
    color: #6e7c89!important;
    cursor: pointer;
    background: transparent;
    border: 0;
    display: block;
}
.row-main{
    padding:1.688rem;
    background :#f1f4f6;
    justify-content:center;
    display: flex;
    flex-wrap: wrap;
}

.bg-custon{
    border-bottom: 1px solid #dee3e8;
    padding-bottom: 0px!important;
    padding-top:0px!important;
}

.active{
    border-bottom: 3px solid #1576d1;
    color:#1576d1;
}
.color-nav-a{
    color:#1576d1!important;
    font-size: 1rem;
    min-width: 4.688rem;
    text-align: center;
}
.py-4{
    margin-top: 0px;
}
.logo-nav{
    height: 3rem;
}
.name{
    font-size: 15px!important;
    line-height: 22px!important;
    color: #475561!important;
}
.button-logout{
    padding: 0px!important;
    margin-right: 0.5rem;
}
.col-main{
    float: none;
    margin: 0 auto;
    padding: 3rem!important;
    margin-top: 1rem;
}
.title{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    color: #2a3743;
    margin-bottom: 0.5rem;
}
.logo{
    margin:0 auto;
    display:block;
    margin-bottom: 1rem;
}

#row-login {
    height: calc(100vh - 16.5vh);
}
@media only screen and (min-width: 600px) {
    .conect {
        margin-bottom: 1.5rem;
        font-size: 0.9rem!important;
        padding-bottom: 1.3rem!important;
        padding-top: 1.3rem!important;
    }
    .password {
        margin-bottom: 1.5rem;
        font-size: 0.9rem!important;
        padding-bottom: 1.3rem!important;
        padding-top: 1.3rem!important;
    }
    .button-large{
        height: 49px;
        padding: 13px 100px;
        font-size: 16px;
        line-height: 23px;
        border-radius: 24px;
    }
    .button-login{
        width: 70%;
        margin-top: 4rem!important;
        margin-bottom: 4rem!important;
        float: none;
        margin: 0 auto;
        display: block;
        height: 44px;
        padding: 11px 32px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background: #1ac98e none;
        border: none;
        border-radius: 22px;
        transition: background-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .form-main{
        margin-top: 3rem;
        margin-bottom: 3rem!important;
    }
  }
.input-create-patient{
    margin-top: 2rem!important;
    font-size: 0.9rem!important;
    padding-bottom: 1.3rem!important;
    padding-top: 1.3rem!important;
    width: 38%!important;
    margin:auto
}
.text-add-patient{
    margin: auto;
    font-size: 1.563rem;
    font-family: roboto;
    margin-top: 3rem;
    color: #2a3743!important;
    text-align: center;
    margin-bottom: 2rem;
}
.checkbox-sex{
    width: 38%!important;
    margin:auto;
    margin-top:2rem;
}

.container-result{
    padding-left: 4rem;
    padding-right: 4rem;
}
.side-bar{
    top: 0;
    bottom: 0;
    z-index: 40;
    background-color: #f1f4f6;
    border-left: 1px solid #dee3e8;
    transition: right .2s ease;
}
.logo-person{
    width: 12rem;
    margin:0 auto;
    display: block;
    margin-top: 4rem;
}
.button-cpf{
    width: 25%;
    margin-top: 2rem!important;
    margin-bottom: 2rem!important;
    float: none;
    margin: 0 auto;
    display: block;
    height: 44px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #1ac98e none;
    border: none;
    border-radius: 22px;
    transition: background-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.input-cpf{
    margin-top: 2rem!important;
    font-size: 0.9rem!important;
    padding-bottom: 1.3rem!important;
    padding-top: 1.3rem!important;
    width: 38%!important;
    margin:auto
}
.text-cpf{
    margin: auto;
    font-size: 1.563rem;
    font-family: roboto;
    margin-top: 3rem;
    color: #2a3743!important;
}
.patient-text-sidebar{
    font-weight: 500;
    color: #2a3743;
    font-size: 18px;
    line-height: 26px;
}
.col-info-patient{
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
.side-bar{
    min-height: 630px;
}
.loadingText{
    top: 56%;
    display: block;
    text-align: center;
    position: absolute;
    margin-top: -0.03em;
    width: 100%;
    font-weight: 600;
}
.loading {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    text-align: center;
}
.loader {
    left: 50%;
    margin-left: -1.5em;
    font-size: 10px;
    border: 0.2em solid rgba(218, 219, 223, 1);
    border-left: .2em solid rgba(58, 166, 165, 1);
    -webkit-animation: spin 1.1s infinite linear;
            animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
    border-radius: 50%;
    width: 3em;
    height: 3em;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -2em;
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
.info-patient {
  width: 60%;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.span-radio {
  margin-left: 1rem;
  color: #475561;
}
.span-label {
  width: 50% !important;
  text-align: center;
}
.col-symptoms {
  margin: auto;
  width: 60%;
}
.input-symptoms {
  font-size: 0.9rem !important;
  padding-bottom: 1.3rem !important;
  padding-top: 1.3rem !important;
  width: 38% !important;
  border-radius: 0px;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.button-search-symptoms {
  background-color: #3a97ef;
  color: white;
  border: 1px solid #ced4da;
  -webkit-border-top-right-radius: 9px;
  -webkit-border-bottom-right-radius: 9px;
  -moz-border-radius-topright: 9px;
  -moz-border-radius-bottomright: 9px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
  margin-left: -1px;
}

.button-remove-symptoms {
  border-style: none;
  background-color: #8c9dad !important;
  color: white;
  border-color: #2088e9;
  padding: 1px 1rem 1px 1rem;
  margin-top: 0.5rem;
  border-radius: 20px;
  transition: 1s;
}

.button-remove-symptoms:hover {
  background-color: #3a4249 !important;
}

.button-add-symptoms {
  border-style: none;
  background-color: #1576d1 !important;
  color: white;
  border-color: #2088e9;
  padding: 1px 1rem 1px 1rem;
  margin-top: 0.5rem;
  border-radius: 20px;
  transition: 1s;
}

.button-add-symptoms:hover {
  background-color: #0d508e !important;
}

.text-options-possible {
  margin: auto;
  font-size: 1.563rem;
  font-family: roboto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #2a3743 !important;
}
.button-off {
  background-color: #fe6967;
  border: #fe6967;
  border-radius: 20rem;
  height: 50px;
  width: 50px;
  display: block;
  margin-left: auto;
}

#main-row {
  height: calc(100vh - 8vh);
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 600px) {
  .button-large {
    padding: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

label > input {
  margin-right: 5px;
}

