.bg-custon{
    border-bottom: 1px solid #dee3e8;
    padding-bottom: 0px!important;
    padding-top:0px!important;
}

.active{
    border-bottom: 3px solid #1576d1;
    color:#1576d1;
}
.color-nav-a{
    color:#1576d1!important;
    font-size: 1rem;
    min-width: 4.688rem;
    text-align: center;
}
.py-4{
    margin-top: 0px;
}
.logo-nav{
    height: 3rem;
}
.name{
    font-size: 15px!important;
    line-height: 22px!important;
    color: #475561!important;
}
.button-logout{
    padding: 0px!important;
    margin-right: 0.5rem;
}