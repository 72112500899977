.loadingText{
    top: 56%;
    display: block;
    text-align: center;
    position: absolute;
    margin-top: -0.03em;
    width: 100%;
    font-weight: 600;
}
.loading {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    text-align: center;
}
.loader {
    left: 50%;
    margin-left: -1.5em;
    font-size: 10px;
    border: 0.2em solid rgba(218, 219, 223, 1);
    border-left: .2em solid rgba(58, 166, 165, 1);
    animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
    border-radius: 50%;
    width: 3em;
    height: 3em;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -2em;
}

@keyframes spin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}