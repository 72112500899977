.col-main{
    float: none;
    margin: 0 auto;
    padding: 3rem!important;
    margin-top: 1rem;
}
.title{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    color: #2a3743;
    margin-bottom: 0.5rem;
}
.logo{
    margin:0 auto;
    display:block;
    margin-bottom: 1rem;
}

#row-login {
    height: calc(100vh - 16.5vh);
}
@media only screen and (min-width: 600px) {
    .conect {
        margin-bottom: 1.5rem;
        font-size: 0.9rem!important;
        padding-bottom: 1.3rem!important;
        padding-top: 1.3rem!important;
    }
    .password {
        margin-bottom: 1.5rem;
        font-size: 0.9rem!important;
        padding-bottom: 1.3rem!important;
        padding-top: 1.3rem!important;
    }
    .button-large{
        height: 49px;
        padding: 13px 100px;
        font-size: 16px;
        line-height: 23px;
        border-radius: 24px;
    }
    .button-login{
        width: 70%;
        margin-top: 4rem!important;
        margin-bottom: 4rem!important;
        float: none;
        margin: 0 auto;
        display: block;
        height: 44px;
        padding: 11px 32px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background: #1ac98e none;
        border: none;
        border-radius: 22px;
        transition: background-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .form-main{
        margin-top: 3rem;
        margin-bottom: 3rem!important;
    }
  }