.side-bar{
    top: 0;
    bottom: 0;
    z-index: 40;
    background-color: #f1f4f6;
    border-left: 1px solid #dee3e8;
    transition: right .2s ease;
}
.logo-person{
    width: 12rem;
    margin:0 auto;
    display: block;
    margin-top: 4rem;
}
.button-cpf{
    width: 25%;
    margin-top: 2rem!important;
    margin-bottom: 2rem!important;
    float: none;
    margin: 0 auto;
    display: block;
    height: 44px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #1ac98e none;
    border: none;
    border-radius: 22px;
    transition: background-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.input-cpf{
    margin-top: 2rem!important;
    font-size: 0.9rem!important;
    padding-bottom: 1.3rem!important;
    padding-top: 1.3rem!important;
    width: 38%!important;
    margin:auto
}
.text-cpf{
    margin: auto;
    font-size: 1.563rem;
    font-family: roboto;
    margin-top: 3rem;
    color: #2a3743!important;
}
.patient-text-sidebar{
    font-weight: 500;
    color: #2a3743;
    font-size: 18px;
    line-height: 26px;
}
.col-info-patient{
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
.side-bar{
    min-height: 630px;
}