a{
    font-size: 14px;
    color: #6e7c89!important;
    cursor: pointer;
    background: transparent;
    border: 0;
    display: block;
}
.row-main{
    padding:1.688rem;
    background :#f1f4f6;
    justify-content:center;
    display: flex;
    flex-wrap: wrap;
}
