.input-create-patient{
    margin-top: 2rem!important;
    font-size: 0.9rem!important;
    padding-bottom: 1.3rem!important;
    padding-top: 1.3rem!important;
    width: 38%!important;
    margin:auto
}
.text-add-patient{
    margin: auto;
    font-size: 1.563rem;
    font-family: roboto;
    margin-top: 3rem;
    color: #2a3743!important;
    text-align: center;
    margin-bottom: 2rem;
}
.checkbox-sex{
    width: 38%!important;
    margin:auto;
    margin-top:2rem;
}
